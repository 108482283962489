//所有项目的公共样式
@import "./common-var";


.block{
	display: block;
	width: 100%;
}

.text-center{
	text-align: center !important;
}

.text-right{
	text-align: right !important;
}

.over-hidden{
	overflow: hidden;
}

//flex sitting start
.flex-center{
	display: flex;
	align-items: center;
	justify-content: center;
}
//flex sitting end

.text-overflow{
	.over-hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

strong{
	font-weight: bold;
}

em{
	font-style: italic;
}

i{
	display: inline-block;
	vertical-align: middle;
}

.text-limit(@lineCount){
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: @lineCount;
	word-break: break-all;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
}

.loopTextLimit(@count,@i: 1) when (@i <= @count){
	.text-limit@{i} when (@i > 1){
		.text-limit(@i);
	}
	.text-limit when (@i = 1) {
		.text-limit(1);
	}
	.loopTextLimit(@count, (@i + 1));
}
.loopTextLimit(5);


//图片超出裁剪
.img-wrap(@width,@height: auto) {
	width: @width;
	height: @height;
	overflow: hidden;
	text-align: center;
	line-height: @height;
	font-size: 0;
}

//设置圆形图片
.avatar(@size) {
	border-radius: 50%;
	overflow: hidden;
	text-align: center;
	width: @size;
	height: @size;
	line-height: @size;
	img {
		border-radius: 50%;
	}
}

.hidden{
	display: none !important;
}

.dis-table{
	display: table;
	width: 100%;
}

.table-cell,.v-align{
	display: table-cell;
	vertical-align: middle;
}

.clear-fix{
	&:after{
		content: '';
		display: inline-block;
		clear: both;
		visibility: hidden;
		height:0;
		line-height:0;
		font-size:0;
	}
}
ul.clear-fix{
	&:after{
		display: list-item;
	}
}

.visibility-hidden{
	visibility: hidden !important;
}

//perfect-scrollbar
@ps_y_width: 10px;
.scrollbar{
	position: relative;
	overflow: hidden;
}
.perfect-scrollbar-reset.ps .ps__rail-y{
	width: @ps_y_width;
	overflow: hidden;
	.ps__thumb-y{
		right: 0;
		background-color: #fff;
	}
}
.ps__thumb-y{
	cursor: pointer;
	width: 10px;
	right: 0;
	background-color:  #dbdbdb;
	box-shadow: 0 0 5px #fff;
}
.ps__rail-y{
	opacity: 0;
	width: @ps_y_width;
	border-radius: 25px;
	&:hover{
		.ps__thumb-y{
			background-color: #dbdbdb;
			width: @ps_y_width;
		}
	}
}
.ps__rail-x{
	display: none !important;
}
.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y{
	background-color: #ededed;
}
//perfect-scrollbar 结束

.img-info(@width: 50px,@height: 80px,@margin-left: 20px){
	>.img{
		float: left;
		.img-wrap(@width,@height);
	}
	.content{
		margin-left: (@width + @margin-left);
		min-height: @height;
		height: @height;
		position: relative;
		display: table;
		width: calc(~"100% - @{width} - @{margin-left}");
		>.title{
			line-height: 1.5;
		}
		>.desc{
		}
	}
}

.avatar-info(@width: 50px,@margin-left: 20px){
	.img{
		float: left;
		.avatar(@width);
	}
	.content{
		margin-left: (@width + @margin-left);
		min-height: @width;
		height: @width;
		position: relative;
		display: table;
		width: calc(~"100% - @{width} - @{margin-left}");
		.title{
			line-height: 1.5;
		}
		.desc{
			line-height: 1.2;
		}
	}
}

.icon-desc{
	font-size: 14px;
	color: @light-black;
	display: table;
	.icon{
		font-size: 0;
		display: table-cell;
		vertical-align: middle;
		width: 25px;
		>i{
			vertical-align: top;
		}
	}
	>i{
	}
	.desc{
		display: table-cell;
		vertical-align: top;
		line-height: 1.5;
	}

	& + &{
		margin-top: 20px;
	}
}

.icon-desc-wrapper{
	overflow: hidden;
	.icon-desc{
		margin-top: 0;
		float: left;
		& + .icon-desc{
			margin-left: 130px;
		}
	}
	&.space-between{
		.icon-desc{
			margin-left: 0;
		}
		.icon-desc:first-child{
			float: left;
		}
		.icon-desc:last-child{
			float: right;
		}
	}
}

//标题 下划线
.title-underline{
	overflow: hidden;
	border-bottom: 1px solid @border;
	.title{
		float: left;
		font-size: 24px;
		color: @blue;
		height: 70px;
		border-bottom: 3px solid @blue;
		padding-top: 20px;
	}
}

//数字-标题  列表
.summary-list {
	overflow: hidden;
	li {
		float: left;
		box-sizing: border-box;
		padding: 0 20px;
		.title {
			line-height: 1.5;
			color: @gray;
			font-size: 14px;
			margin-bottom: 15px;
		}
		.desc {
			line-height: 1.2;
			color: @red;
			font-size: 24px;
		}
	}
	&.reverse{
		.title{
			margin-bottom: 0;
		}
		.desc{
			margin-bottom: 10px;
		}
	}
}

.offcanvas{
	position: fixed;
}

.cursor-pointer{
	cursor: pointer;
}

.clear{
	clear: both;
}

.btn-reset(@width,@height){
	width: @width;
	height: @height;
}

.fwb{
	font-weight: bold;
}

.pull-left{
	float: left !important;
}

.pull-right{
	float: right !important;
}

.pt30{
	padding-top: 30px !important;
}

.pb30{
	padding-bottom: 30px !important;
}

.mr5{
	margin-right: 5px !important;
}

.mb30{
	margin-bottom: 30px !important;;
}

.mb25{
	margin-bottom: 25px !important;;
}

.mb20{
	margin-bottom: 20px !important;;
}

.mb15{
	margin-bottom: 15px !important;;
}

.mb5{
	margin-bottom: 5px !important;;
}

.mt20{
	margin-top: 20px !important;
}

.mt60{
	margin-top: 60px !important;;
}

.mb0{
	margin-bottom: 0 !important;
}
