@import "./var";
@import "../../../common-var";
@import "../../../common-tool";
@import "./tool";

.plus-quote {
	color: @blue;
	font-size: 46px;

	&:after {
		content: '+';
		font-size: 20px;
		vertical-align: top;
	}
}

.quote-desc {
	width: 630px;
	margin: 0 auto;
	position: relative;
	padding: 30px 80px;

	.icon {
		position: absolute;

		&.icon-quote-l {
			left: 0;
			top: 0;
		}

		&.icon-quote-r {
			right: 0;
			bottom: 0;
		}
	}

	.desc {
		color: @light-black;
		font-size: 16px;
		line-height: 1.5;
		margin-bottom: 15px;
	}

	.speaker {
		color: @gray;
		font-size: 14px;
		line-height: 1.2;
		text-align: right;

		&:before {
			content: '——';
			margin-right: 5px;
		}
	}
}


.page {
	margin-top: @header_height;
}

.page-index {
	margin-top: -@header_height;

	.banner {
		margin-bottom: 130px;
		max-height: 750px;
		height: 750px;
		overflow: hidden;
		position: relative;
		min-width: @container_width;
		background-size: cover;
		background-position: center;

		.content {
			text-align: center;
			position: absolute;
			top: 81%;
			width: 100%;

			.banner-title {
				font-size: 38px;
				font-weight: bold;
				color: #ffffff;
				text-shadow: 0 2px 4px rgba(21, 20, 20, 0.39);
				line-height: 1.5;
				margin-bottom: 40px;
			}

			.banner-desc {
				line-height: 1.5;
				font-size: 24px;
				color: #ffffff;
				text-shadow: 0 2px 4px rgba(21, 20, 20, 0.39);
			}
		}
	}

	.product-list {
		margin: 0 -40px 150px;
		display: flex;

		li {
			overflow: hidden;
			position: relative;
			flex-grow: 1;
			transition: all .3s;
			&.active,&:hover {
				flex-grow: 1.3;
				transform: translate3d(0,0,0);
				a{
					&:before{
						background-color: rgba(0, 0, 0, 0.2);
					}
					.content{
						.bottom{
							.get-more{
								opacity: 1;
							}
						}
					}
				}
			}

			a {
				background-position: left top;
				height: 520px;
				color: #fff;
				display: block;
				padding-top: 130px;
				position: relative;

				&:before {
					content: '';
					display: block;
					transition: all .3s;
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					z-index: 1;
					background-color: rgba(0, 0, 0, 0.4);
				}

				.content {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					z-index: 2;
					padding: 130px 40px 30px;

					.product-title {
						display: inline-block;
						font-size: 22px;
						line-height: 1.2;
						position: relative;
						margin-bottom: 125px;

						&:after {
							content: '';
							display: block;
							position: absolute;
							left: 0;
							bottom: -20px;
							border-bottom: 2px solid #fff;
							width: 20px;
						}
					}

					.product-desc {
						font-size: 16px;
						line-height: 1.5;
						width: 190px;
					}

					&:hover {
						.bottom {
							.get-more {
								border-color: #fff;
							}
						}
					}

					.bottom {
						position: absolute;
						left: 40px;
						right: 40px;
						bottom: 30px;

						.get-more {
							transition: opacity .8s;
							.flex-center;
							opacity: 0;
							width: 76px;
							height: 28px;
							border: 1px solid #cccccc;
							font-size: 14px;
							margin-bottom: 10px;
						}

						.order {
							float: right;
							width: 100%;
							text-align: right;
							font-size: 42px;
							line-height: 1.2;
						}
					}
				}

			}
		}
	}

	.banner1 {
		min-width: @container_width;
		.flex-center;
		max-height: 690px;
		overflow: hidden;
		background-color: @blue;

		&:hover{
			.img{
				img{
					transform: scale(1.05);
				}
			}
		}

		.img {
			height: 100%;
			width: 51%;
			overflow: hidden;
			flex-shrink: 0;
			position: relative;
			img{
				transition: all .3s;
				width: 100%;
				object-fit: cover;
			}
			&:after{
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				background-color: rgba(51, 51, 51, 0.25);
			}
		}

		.content {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-left: 80px;
			flex-grow: 1;

			.title {
				font-size: 30px;
				font-weight: bold;
				line-height: 1.5;
				color: #ffffff;
				margin-bottom: 70px;
				max-width: 460px;
			}

			.desc {
				font-size: 20px;
				line-height: 1.5;
				color: #ffffff;
				margin-bottom: 75px;
				max-width: 460px;
			}

			.btn {
				box-shadow: 0 1px 3px 0 rgba(40, 38, 2, 0.35);
			}
		}
	}

	.platform-info-wrapper {
		background-color: #fafafa;
		padding: 120px 0 180px;

		.platform-info {
			border-radius: 6px;
			background-color: #fff;
			width: 1000px;
			margin: 0 auto;
			padding: 85px 75px 80px;
			box-shadow: 0 10px 24px 0 rgba(17, 17, 18, 0.1);

			.platform-desc {
				font-size: 20px;
				color: @black;
				line-height: 1.5;
				margin-bottom: 55px;
				text-align: center;
			}
		}
	}
}

footer {
	.top-wrapper {
		// height: 200px;
		padding-top: 30px;
		padding-bottom: 30px;
		background-color: #eef2f8;

		> .container {
			display: flex;
			justify-content: space-between;

			.footer-list {
				width: 950px;
				display: flex;

				> li {
					&:first-child {
						width: 245px;
						padding-right: 20px;
					}

					&:nth-child(2) {
						width: 220px;
						padding-right: 20px;
					}

					.footer-list-title {
						font-size: 18px;
						line-height: 1.2;
						color: @deep-blue;
						font-weight: bold;
						margin-bottom: 20px;
					}

					.sub-footer-list {
						> li {
							font-size: 14px;
							line-height: 1.2;
							color: @light-black;

							a {
								color: @light-black;
							}

							& + li {
								margin-top: 15px;
							}
						}
					}
				}
			}

			.qr-code-wrapper {
				text-align: center;
				margin-top: 5px;

				.img {
					width: 105px;
					height: 105px;
					background-color: #fff;
					margin: 0 auto 15px;
					.flex-center;

					img {
						width: 95px;
						height: 95px;
						.flex-center;
					}
				}

				.desc {
					font-size: 12px;
					color: @gray;
					line-height: 1.2;
				}
			}
		}
	}

	.bottom-wrapper {
		font-size: 12px;
		color: #fff;
		background-color: @deep-blue;
		height: 40px;

		a {
			color: #fff;
		}

		> .container {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
}

.module-banner {
	color: #fff;
	position: relative;
	min-width: @container_width;
	margin-bottom: 130px;
	>img{
		width: 100%;

	}

	.content {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		.flex-center;
		flex-direction: column;
		background-color: rgba(51, 51, 51, 0.27);

		.title {
			font-size: 28px;
			line-height: 1.5;
		}

		.desc {
			margin-top: 30px;
			font-size: 18px;
			line-height: 1.5;
		}
	}
}

.stat {
	color: #fff;
	height: 580px;
	.flex-center;
	flex-direction: column;
	.bg(lib-bg-stat, jpg, center, cover);

	> .container {
		.stat-list {
			display: flex;
			justify-content: space-between;
			align-items: center;
			&.stat-list-s{
				width: 885px;
				margin-left: auto;
				margin-right: auto;
			}

			> li {
				.data {
					margin-bottom: 20px;
					display: flex;
					align-items: baseline;

					.number,.unit-text {
						font-size: 46px;
						line-height: 1.2;
					}

					.small {
						font-size: 16px;
						margin-left: 15px;
						line-height: 1.2;
					}
				}

				.data-desc {
					line-height: 1.2;
					font-size: 20px;
				}
			}

			& + .btn{
				margin-top: 60px;
			}
		}

		.project-recommend-swiper {
			margin-top: 55px;
			@dialog-border-color: rgba(255, 255, 255, 0.4);
			@quote-height: 18px;
			margin-bottom: 40px;
			color: #fff;
			padding-top: @quote-height;
			padding-bottom: @quote-height;

			.swiper-slide {
				.quote-desc {
					&:before, &:after {
						content: '';
						display: inline-block;
						position: absolute;
						width: 630px;
					}

					&:before {
						left: -80px;
						bottom: 0;
						top: 0;
						border-left: 1px solid @dialog-border-color;
						border-bottom: 1px solid @dialog-border-color;
					}

					&:after {
						right: -80px;
						bottom: 0;
						top: 0;
						border-top: 1px solid @dialog-border-color;
						border-right: 1px solid @dialog-border-color;
					}

					.icon {
						@x-pos: 79px;
						&:before {
							content: '';
							display: inline-block;
							position: absolute;
							width: 40px;
							border-top: 1px solid @dialog-border-color;
						}

						&.icon-quote-l {
							top: -@quote-height;

							&:before {
								left: -@x-pos;
								bottom: 0;
								border-top: 1px solid @dialog-border-color;
								top: 18px;
							}
						}

						&.icon-quote-r {
							bottom: -@quote-height;

							&:before {
								right: -@x-pos;
								border-top: 1px solid @dialog-border-color;
								bottom: @quote-height;
							}
						}
					}

					.desc {
						color: #fff;
					}

					.speaker {
						color: #fff;

						&:before {
						}
					}
				}
			}
		}

		.btn {
			margin-left: auto;
			margin-right: auto;
			display: block;
		}
	}
}

.video-wrapper {
	background-color: #f7f9fa;
	padding-top: 130px;
	padding-bottom: 135px;

	.title {
		font-size: 30px;
		line-height: 1.5;
		color: @black;
		margin-bottom: 80px;
		max-width: 80%;
		margin-left: auto;
		margin-right: auto;
	}

	.video {
		cursor: pointer;
		margin-left: 80px;
		margin-right: 80px;
		height: 480px;
		position: relative;
		box-shadow: 0 8px 24px 2px rgba(1, 24, 35, 0.2);
		border-radius: 6px;
		overflow: hidden;

		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			background-color: rgba(51, 51, 51, 0.25);
		}

		.icon-video {
			position: absolute;
			left: 50%;
			top: 50%;
			margin-left: -30px;
			margin-top: -30px;
			z-index: 2;
		}

		img{
			transition: all 3s;
		}

		&:hover{
			img{
				transform: scale(1.1);
			}
		}
	}
}


.page-lib {
	.module-banner {
		margin-bottom: 70px;
	}

	.lib-recommend-swiper {
		margin-bottom: 75px;
		min-width: @container_width;
		padding-top: 70px;
		padding-bottom: 70px;
		margin-left: -80px;

		.swiper-slide {
			.flex-center;

			.img {
				margin-right: 85px;
				border-radius: 6px;
				flex-shrink: 0;
				width: 820px;
				overflow: hidden;
				box-shadow: 0 3px 32px 0 rgba(3, 73, 149, 0.36);
			}

			.content {
				width: 350px;
			}
		}

		> .swiper-pagination-bullets {
			bottom: 0;
		}
		.swiper-pagination-bullet{
			width: 13px;
			height: 13px;
		}
	}

	.peroration {
		height: 505px;
		background-color: #fff;
		.flex-center;
		flex-direction: column;

		.title {
			font-size: 30px;
			line-height: 1.5;
			color: @black;
			margin-bottom: 45px;
			text-align: center;
			max-width: 80%;
			margin-left: auto;
			margin-right: auto;
		}

		.btn-group {
			.btn-org-apply {
			}

			.btn-lib-apply {
			}
		}
	}
}

.mfp-content {
	text-align: center;
}

//流动书箱
.page-move {

	.info-swiper-wrapper {
		margin-bottom: 130px;
		display: flex;
		align-items: center;
		position: relative;

		.info-swiper {
			margin-left: -70px;
			height: 520px;
			width: 810px;

		}

		.info-list {
			height: 520px;
			display: flex;
			flex-direction: column;
			width: 440px;
			border-right: 1px solid @border;
			border-bottom: 1px solid @border;

			&.info-list-3 {
				> li {
					height: 33.33%;
				}
			}

			> li {
				transition: all .3s;
				border-top: 1px solid @border;
				flex-grow: 1;
				position: relative;
				padding-left: 35px;
				padding-right: 20px;
				display: flex;
				align-items: center;

				.content {
					display: flex;

					.number {
						flex-shrink: 0;
						font-size: 20px;
						line-height: 1.5;
						color: @blue;
						font-style: italic;
					}

					.desc {
						font-size: 20px;
						line-height: 1.5;
						color: @light-black;
						margin-left: 15px;
					}
				}
			}
		}

		.mask-content {
			height: 160px;
			transition: all .3s;
			position: absolute;
			width: 440px;
			right: 0;
			top: 0;
			bottom: 0;
			padding: 35px 20px 35px 35px;
			background-color: #fff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			box-shadow: 0 2px 18px 0 rgba(3, 73, 149, 0.2);
			z-index: -1;
			opacity: 0;
			.triangle-left;

			&.active {
				opacity: 1;
				z-index: 2;
			}

			.title {
				min-height: 36px;
			}
		}
	}

}

.recommend-swiper-wrapper {
	margin-bottom: 130px;
	display: flex;
	align-items: center;
	position: relative;

	.recommend-swiper {
		margin-left: -70px;
		height: 520px;
		width: 810px;
		.swiper-slide{
			background-color: #fff;
			img{
			}
		}
	}

	.recommend-list {
		height: 520px;
		display: flex;
		flex-direction: column;
		width: 440px;
		border-right: 1px solid @border;
		border-bottom: 1px solid @border;

		> li {
			transition: all .3s;
			border-top: 1px solid @border;
			flex-grow: 1;
			position: relative;
			padding-left: 35px;
			padding-right: 20px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.triangle-left;

			&:before {
				left: 0;
				transition: all .3s;
			}

			.title {
				margin-bottom: 10px;
				transition: all .3s;
			}

			.desc {
				&:before {
					transition: all .3s;
					opacity: 0;
				}
			}

			&:hover, &.active {
				box-shadow: 0 2px 18px 0 rgba(3, 73, 149, 0.2);

				&:before {
					left: -14px;
				}

				.title {
				}

				.desc {
					&:before {
						opacity: 1;
					}
				}
			}
		}
	}
}

//童书乐捐
.page-donate {

}

.peroration-common {
	height: 510px;

	> .container {
		.flex-center;
		height: 100%;

		.content {
			.title {
				font-size: 30px;
				line-height: 1.5;
				margin-bottom: 20px;
				color: @black;
			}

			.desc {
				font-size: 16px;
				line-height: 1.5;
				color: @light-black;
			}
		}

		.qr-code-wrapper {
			margin-left: 90px;
			text-align: center;

			.qr-code {
				width: 150px;
				height: 150px;
				padding: 10px;
				box-shadow: 0 2px 14px -3px rgba(0, 0, 0, 0.33);
				margin-bottom: 16px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.desc {
				font-size: 14px;
				color: @gray;
			}
		}
	}
}

.page-news {
	.module-banner {
		margin-bottom: 0;
	}

	.category{
		>.container{
			height: 70px;
			background-color: #fff;
			.category-list{
				display: flex;
				align-items: center;
				height: 100%;
				>li{
					height: 100%;
					position: relative;
					& + li{
						margin-left: 85px;
					}
					a{
						font-size: 16px;
						display: flex;
						align-items: center;
						height: 100%;
						color: @light-black;
					}
					&:after{
						content: '';
						transition: all .3s;
						position: absolute;
						left: 50%;
						transform: translateX(-50%);
						bottom: 0;
						width: 0;
						background-color: @blue;
						border-bottom: 2px solid @blue;
						display: inline-block;
						opacity: 0;
					}
					&.active,&:hover{
						a{
							color: @blue;
							&:after{
								opacity: 1;
							}
						}
						&:after{
							width: 100%;
							opacity: 1;
						}
					}
				}
			}
		}
	}

	.wrapper {
		padding-top: 30px;
		padding-bottom: 130px;

		.main {
			.news-list {
				padding-bottom: 80px;

				> li {
					padding: 30px 25px;
					border-bottom: 1px solid @border;

					&:hover {
						a {
							.img {
								img {
									transform: scale(1.1);
								}
							}

							.content {
								.title {
									color: @blue;
								}
							}
						}
					}

					a {
						display: flex;
						height: 186px;

						.img {
							flex-shrink: 0;
							.img-wrap(280px, 186px);
							margin-right: 30px;

							img {
								transition: all .3s;
							}
						}

						.content {
							padding-top: 25px;
							min-width: 0;

							.title {
								font-size: 18px;
								color: @black;
								font-weight: bold;
								line-height: 1.5;
								margin-bottom: 20px;
								height: 55px;
							}

							.time {
								font-size: 14px;
								color: @gray;
								line-height: 1.5;
								margin-bottom: 20px;
							}

							.desc {
								line-height: 1.5;
								font-size: 14px;
								color: @light-black;
							}
						}
					}
				}
			}

			padding-bottom: 90px;
		}
	}
}

aside {
	padding-bottom: 90px;

	.news-recommend-list {
		padding: 0 25px;

		> li {
			padding: 30px 0;
			border-bottom: 1px dashed @border;

			a {
				display: block;

				.title {
					font-size: 16px;
					color: @light-black;
					line-height: 1.5;
					margin-bottom: 15px;
				}

				.desc {
					font-size: 14px;
					color: @gray;
					line-height: 1.5;
				}
				&:hover{
					.title{
						color: @blue;
					}
				}
			}
		}
	}
}

.page-news-detail {
	padding-bottom: 130px;

	.bread-nav {
		height: 65px;
	}

}

.page-about {
	padding-bottom: 160px;

	.module-banner {
		margin-bottom: 110px;
	}

	.title-under-line {
		.title {
			max-width: 80%;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.about-swiper-wrapper{
		margin: 0 -40px;
		overflow: hidden;
		.about-swiper {
			width: 900px;
			height: 480px;


			.swiper-slide {
				text-align: center;
				overflow: hidden;
				font-size: 18px;
				background: #fff;
				border-radius: 6px;

				/* Center slide text vertically */
				display: flex;
				justify-content: center;
				align-items: center;
				transform: scale(0.75);
				transition: transform .3s;


				&.swiper-slide-prev{
					left: 228px !important;
					transition: left .3s linear .3s;
					&:before,&:after{
						content: '';
						position: absolute;
						left: 506px;
						z-index: 2;
						.icon-swiper-radius;
					}
					&:before{
						top: 0;
					}
					&:after{
						bottom: 0;
						transform: rotate(-90deg);
					}
				}
				&.swiper-slide-next{
					transition: left .3s linear .3s;
					left: -228px !important;
					&:before,&:after{
						content: '';
						position: absolute;
						right: 506px;
						z-index: 2;
						.icon-swiper-radius;
					}
					&:before{
						top: 0;
						transform: rotate(90deg);
					}
					&:after{
						bottom: 0;
						transform: rotate(180deg);
					}
				}


			}

			.swiper-slide-active, .swiper-slide-duplicate-active {
				transform: scale(1);
				z-index: 3;
				border-radius: 6px;
				overflow: hidden;
				box-shadow: 0 20px 40px 0 rgba(1, 8, 12, 0.24);
			}

		}
	}

	.read-union {
		display: flex;
		align-items: center;
		margin-bottom: 120px;


		.img {
			.img-wrap(580px, 390px);
			margin-right: 65px;
			flex-shrink: 0;
			border-radius: 6px;
		}

		.content {
			height: 100%;
			font-size: 16px;
			color: @light-black;
			line-height: 1.9;
		}
	}

	.peroration {
		.title-under-line {
			margin-bottom: 55px;
		}
	}
}

.page-help{
	.module-banner{
		margin-bottom: 0;
	}
	.main{
		padding-top: 90px;
		padding-bottom: 150px;
		.module-list{
			margin-right: -30px;
			display: flex;
			flex-wrap: wrap;
			>li{
				width: 575px;
				height: 300px;
				margin-right: 30px;
				margin-bottom: 30px;
				display: flex;
				padding: 0 25px;
				background-color: #fff;
				border-radius: 6px;
				box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.12);

				.img{
					width: 250px;
					.flex-center;
					text-align: center;
					flex-shrink: 0;
					img{
						max-width: 100%;
					}
				}
				.content{
					padding-left: 50px;
					padding-top: 60px;
					width: 275px;
					.title{
						font-size: 24px;
						color: @black;
						margin-bottom: 15px;
						font-weight: bold;
						line-height: 1.5;
					}
					.sub-module-list{
						font-size: 16px;
						>li{
							line-height: 1.5;
							margin-bottom: 5px;
							&:hover{
								a{
									color: @blue;
								}
							}
							a{
								display: inline-block;
								vertical-align: middle;
								width: 100%;
								color: @light-black;
								.text-overflow;
							}
						}
					}
				}
			}
		}
	}
}

.model-mask{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(51, 51, 51, 0.25);
	z-index: 10;
	display: none;
}

.model{
	z-index: 11;
	display: none;
	position: fixed;
	left: 50%;
	top: 50%;
	width: 300px;
	height: 300px;
	background-color: #fff;

	//webkit浏览器自定义滚动条
	::-webkit-scrollbar {
		width: 6px;
		height: 9px;
		border-radius: 5px;
	}
	::-webkit-scrollbar-button, ::-webkit-scrollbar-track{
		display: none;
	}
	::-webkit-scrollbar-track-piece{
		background-color: #F2F2F2;
		border-radius: 5px;
	}
	::-webkit-scrollbar-thumb{
		background-color: #ccc;
		border-radius: 5px;
	}

	&.active{
		transform: translate(-50%, -50%);
	}
	.model-header{
		padding: 15px;
		border-bottom: 1px solid #f5f5f5;
		h4{
			font-size: 24px;
		}
	}
	.model-content{
		height: 85%;
		flex-grow: 1;
		border-bottom: 1px solid #f5f5f5;
		padding: 15px;
		overflow-y: auto;
		.content{
			font-size: 16px;
		}
	}
	.model-footer{
		padding: 10px;
	}
	&.model-lg{
		width: 800px;
		height: 80%;
		.model-content{
		}
	}
}

.count{
	white-space: nowrap;
	display: inline-block;
}

