@import "./var";
@import "../../../common-var";

.iconGen(@icon,@width:10px,@height:10px){
  background: url("@{iconDir}/@{icon}.png") no-repeat center;
  width: @width;
  height: @height;
  background-origin:content-box;
  background-size: @width @height;
}

.icon-quote-l{
  .iconGen(icon-quote-l,38px,36px);
}

.icon-quote-r{
  .iconGen(icon-quote-r,39px,36px);
}

.icon-swiper-radius{
  .iconGen(icon-swiper-radius,6px,6px);
}

