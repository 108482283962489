@import "./var";
@import "../../../common-var";
@import "../../../common-tool";
@import "./tool";

//以下注释用于快速复制
//.title-show-list{
//  li{
//
//    .num{
//    }
//    .desc{
//    }
//  }
//}

.container {
	width: @container_width;
	margin-left: auto;
	margin-right: auto;
}

.btn {
	display: inline-block;
	width: 120px;
	height: 30px;
	line-height: 30px;
	font-size: 14px;
	border: 0;
	padding: 0;
	transition: all .3s;
	text-align: center;
	cursor: pointer;

	.btn-color(@bg-color,@border-color: @bg-color,@color: @bg-color) {
		background-color: @bg-color;
		color: #fff;
		border: 1px solid @border-color;
		&.btn-border {
			color: @color;
			line-height: 29px;
			background-color: #fff;

			&:hover {
				background-color: @border-color;
				color: #fff;
			}
		}
	}

	&.btn-transparent {
		background-color: transparent;
		color: #fff;
		border: 1px solid #fff;
	}

	&.btn-white {
		background-color: #fff;
		color: @blue;
	}

	&.btn-gold {
		background-color: @gold;
		color: #004ea2;

		&.btn-shadow {
			box-shadow: 0 4px 26px 2px rgba(208, 197, 13, 0.3);
		}
	}

	&.btn-blue {
		background-color: @blue;
		color: #fff;

		&.btn-shadow {
			box-shadow: 0 4px 26px 2px rgba(7, 147, 210, 0.3)
		}
	}

	&.btn-radius {
		border-radius: 25px;
	}

	&.btn-square-radius {
		border-radius: 6px;
	}

	&.btn-sm {
		width: auto;
		padding: 0 10px;
		height: 24px;
		line-height: 24px;
		font-size: 12px;

		&.btn-border {

		}
	}

	&.btn-md {
		width: 180px;
		height: 50px;
		line-height: 50px;
		font-size: 16px;

		&.btn-border {

		}
	}

	&.btn-block {
		width: 100%;
		display: block;
	}

	&.btn-square {
		border-radius: 0;
	}

	&.btn-radius-square {
		border-radius: 4px;
	}
}


.bg-white {
	background-color: #fff !important;
}


.text-blue {
	color: @blue !important;
}

.text-black {
	color: @black !important;
}

.text-red {
	color: @red !important;
}

.text-gray {
	color: @gray !important;
}

.text-white {
	color: #fff !important;
}

.border-bottom {
	border-bottom: 1px solid @border !important;
}

.border-top {
	border-top: 1px solid @border !important;
}

.border-left {
	border-left: 1px solid @border !important;
}

.border-right {
	border-right: 1px solid @border !important;
}

.btn-group {
	.flex-center;

	.btn {
		& + .btn {
			margin-left: 30px;
		}
	}
}

//视频icon
.icon-video {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background-color: #fff;
	line-height: 60px;
	text-align: center;
	position: relative;

	&:after {
		content: '';
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -7px;
		margin-top: -15px;
		display: inline-block;
		width: 0;
		height: 0;
		border-top: 14px solid transparent;
		border-bottom: 14px solid transparent;
		border-left: 18px solid @blue;
	}

	&.icon-video-blue {
		&:after {
			border-left: 18px solid @blue;
		}
	}
}

.title-under-line {
	&.title-under-line-center {
		.title {
			text-align: center;

			&:before {
				left: 50%;
				margin-left: -10px;
			}
		}
	}

	.title {
		font-size: 24px;
		line-height: 1.5;
		color: @black;
		position: relative;

		&:before {
			content: '';
			display: inline-block;
			width: 20px;
			height: 3px;
			background-color: @blue;
			position: absolute;
			left: 0;
			bottom: -18px;
		}
	}

	.desc {
		font-size: 16px;
		line-height: 1.5;
		color: @light-black;
		margin-top: 40px;
	}
}

//magnific-popup  start

/* overlay at start */
.mfp-fade.mfp-bg {
	opacity: 0;

	-webkit-transition: all 0.15s ease-out;
	-moz-transition: all 0.15s ease-out;
	transition: all 0.15s ease-out;
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
	opacity: 0.8;
}

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
	opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
	opacity: 0;

	-webkit-transition: all 0.15s ease-out;
	-moz-transition: all 0.15s ease-out;
	transition: all 0.15s ease-out;
}

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
	opacity: 1;
}

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
	opacity: 0;
}

//magnific-popup  end

//左三角形
.triangle-left {
	&:before {
		content: '';
		display: block;
		position: absolute;
		z-index: 1;
		left: -14px;
		top: 50%;
		margin-top: -7px;
		width: 0;
		height: 0;
		border-top: 6px solid transparent;
		border-right: 14px solid #fff;
		border-bottom: 6px solid transparent;
	}
}

.bg-gray {
	background-color: @bg-gray !important;
}

.wrapper {
	.container {
		overflow: hidden;

		.main {
			float: left;
			width: 820px;
			margin-right: 40px;
			background-color: #fff;
		}

		aside {
			float: right;
			width: 320px;
			background-color: #fff;
		}
	}
}

.aside-title {
	font-size: 22px;
	background-color: @blue;
	padding-left: 25px;
	display: flex;
	align-items: center;
	color: #fff;
	height: 60px;
}

.pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;

	.page-nav {
		border: 1px solid @border;
		width: 25px;
		height: 25px;
		border-radius: 4px;
		.flex-center;
		color: @gray;
	}

	.number {
		color: @light-black;
		margin-left: 15px;
		margin-right: 15px;

		&.on {
			color: @blue;
			position: relative;

			&:after {
				content: '';
				display: inline-block;
				border-bottom: 1px solid @blue;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
			}
		}
	}
}

//面包屑导航
.bread-nav {
	display: flex;
	align-items: center;

	> li {
		& + li {
			&:before {
				content: '>';
				color: @gray;
				font-size: 14px;
				display: inline-block;
				margin-left: 5px;
				margin-right: 5px;
			}
		}

		a {
			display: inline-block;
			color: @black;
			font-size: 14px;
		}
	}
}

.article-wrapper {
	padding: 75px 40px 105px;
	background-color: #fff;

	.meta {
		padding-bottom: 25px;
		border-bottom: 1px solid @border;

		.title {
			font-size: 32px;
			color: @black;
			line-height: 1.5;
			text-align: center;
			margin-bottom: 25px;
		}

		.time {
			text-align: center;
			font-size: 14px;
			color: @light-black;
		}
	}
}

.no-data{
	font-size: 14px;
	color: @light-black;
	line-height: 1.5;
	padding: 10px 0;
	text-align: center;
}

.mfp-content{
	video{
		width: 80%;
		max-width: 100%;
		max-height: 100%;
	}
	button.mfp-close{
		color: #fff;
		font-size: 46px;
		border: 1px solid #fff;
		border-radius: 50%;
		transition: all .3s;
	}
}

