@import "./var";
@import "../../../common-var";
@import "../../../common-tool";
@import "./tool";

@media screen and (max-width: 1376px) {
  .page-index{
    .banner{
      margin-bottom: 80px;
      img{
      }
      .content{
        .banner-title{
          font-size: 28px;
        }
        .banner-desc{
          font-size: 18px;
        }
      }
    }

    .product-list{
      margin-bottom: 100px;
      &:hover{
        li{
          &.active{
          }
          &:hover{
          }
        }
      }
      li{
        &.active{
        }
        a{

          &:before{

          }
          .content{
            .product-title{
              &:after{
              }
            }
            .product-desc{
            }
            &:hover{
              .bottom{
                .get-more{
                }
              }
            }
            .bottom{
              .get-more{
              }
              .order{
              }
            }
          }

        }
      }
    }

    .banner1{
      .img{
      }
      .content{
        padding-left: 40px;
        .title{
          font-size: 24px;
          margin-bottom: 35px;
        }
        .desc{
          font-size: 18px;
          margin-bottom: 30px;
        }
        .btn{
        }
      }
    }


  }


  .btn{
    &.btn-md{
      width: 160px;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
    }
  }
}
